var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","tag":"section"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"absolute":"","top":"","right":"","fab":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" fa-plus ")])],1)]}}])},[_c('v-list',_vm._l((_vm.leagues),function(item,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.add(item.value)}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.text))])],1)}),1)],1),_c('v-data-table',{attrs:{"items":_vm.events,"headers":[
        { text: 'Beginn', value: 'startDate', sortable: false },
        { text: 'Liga', value: 'liga.shortName', sortable: false },
        { text: 'Mannschaften', value: 'teams', sortable: false },
        { text: 'Status', value: 'status', sortable: false, align: 'center' },
        { text: 'Hinweise', value: 'publicComment', sortable: false, align: 'center'},
        { text: 'Anmerkungen', value: 'internalComment', sortable: false, align: 'center'},
        { text: 'Halle', value: 'venue', sortable: false, align: 'center'},
        { text: 'Bearbeiten', value: 'edit', sortable: false, align: 'center' },
        { text: 'Löschen', value: 'delete', sortable: false, align: 'center' },
        { text: 'Live-Eingabe', value: 'input', sortable: false, align: 'center' },
        { text: 'Live-Anzeige', value: 'view', sortable: false, align: 'center' }
      ],"mobile-breakpoint":0,"items-per-page":-1,"hide-default-footer":"","item-class":function (item) { return ({'stbw2023bold': item.status === 'EventMovedOnline', 'stbw2023red': item.status === 'EventStarted'}); }},scopedSlots:_vm._u([{key:"item.startDate",fn:function(ref){
      var item = ref.item;
return [(item.status === 'EventPostponed')?_c('i',[_c('span',{staticStyle:{"text-decoration":"line-through"}},[_vm._v(_vm._s(_vm._f("dateformat")(item.startDate,'DD.MM.YYYY')))]),_c('br'),_vm._v("wird verschoben,"),_c('br'),_vm._v("Termin noch offen")]):_c('span',{domProps:{"innerHTML":_vm._f("dateformat")(item.startDate,'dddd<br>DD.MM.YYYY<br>HH:mm')}})]}},{key:"item.teams",fn:function(ref){
      var item = ref.item;
return _vm._l((item.teams),function(t){return _c('div',{key:t.team._id},[_vm._v(_vm._s(t.team.name))])})}},{key:"item.status",fn:function(ref){
      var item = ref.item;
return [_c('base-edit-dialog-select',{attrs:{"value":item.status,"items":_vm.status,"id":item._id},on:{"input":function (ref) {
            var value = ref.value;
            var id = ref.id;

            return _vm.$emit('changestatus', {id: id, value: value});
      }}})]}},{key:"item.publicComment",fn:function(ref){
      var item = ref.item;
return [(item.publicComment)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","fab":"","text":""}},on),[_c('v-icon',[_vm._v(" far fa-info-circle ")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(item.publicComment)+" ")])]):_vm._e()]}},{key:"item.internalComment",fn:function(ref){
      var item = ref.item;
return [(item.internalComment)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","fab":"","text":""}},on),[_c('v-icon',[_vm._v(" far fa-info-circle ")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(item.internalComment)+" ")])]):_vm._e()]}},{key:"item.venue",fn:function(ref){
      var item = ref.item;
return [(item.venue)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","fab":"","text":""},on:{"click":function($event){$event.stopPropagation();return _vm.openmaps(item.venue.address)}}},on),[_c('v-icon',[_vm._v(" far fa-circle-h ")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(item.venue.name)),_c('br'),_vm._v(" "+_vm._s(item.venue.address.streetAddress)+" "+_vm._s(item.venue.address.streetNumber)),_c('br'),_vm._v(" "+_vm._s(item.venue.address.postalCode)+" "+_vm._s(item.venue.address.addressLocality)+" ")])]):_vm._e()]}},{key:"item.edit",fn:function(ref){
      var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","small":"","fab":""},on:{"click":function($event){$event.stopPropagation();return _vm.edit(item._id)}}},[_c('v-icon',[_vm._v(" far fa-pencil ")])],1)]}},{key:"item.delete",fn:function(ref){
      var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","small":"","fab":""},on:{"click":function($event){$event.stopPropagation();return _vm.del(item._id)}}},[_c('v-icon',[_vm._v(" far fa-trash-alt ")])],1)]}},{key:"item.input",fn:function(ref){
      var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","fab":"","small":""},on:{"click":function($event){$event.stopPropagation();return (function (ev) { return _vm.input(item._id, ev); }).apply(null, arguments)}}},[_c('span',{staticClass:"fa-stack fa-1x"},[_c('i',{staticClass:"far fa-play fa-stack-2x",staticStyle:{"margin-left":"0px","margin-top":"-6px","font-size":"300%"}}),_c('i',{staticClass:"far fa-pencil-alt fa-stack-1x",staticStyle:{"margin-left":"4px","margin-top":"1px","font-size":"100%","text-shadow":"0 0 2px white"}})])])]}},{key:"item.view",fn:function(ref){
      var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","fab":"","small":""},on:{"click":function($event){$event.stopPropagation();return (function (ev) { return _vm.view(item._id); }).apply(null, arguments)}}},[_c('span',{staticClass:"fa-stack fa-1x"},[_c('i',{staticClass:"far fa-play fa-stack-2x",staticStyle:{"margin-left":"0px","margin-top":"-5px","font-size":"300%"}}),_c('i',{staticClass:"far fa-eye fa-stack-1x",staticStyle:{"margin-left":"4px","font-size":"100%","text-shadow":"0 0 2px white"}})])])]}}])})],1),_c('edit-event',{attrs:{"id":_vm.dialog.id},model:{value:(_vm.dialog.open),callback:function ($$v) {_vm.$set(_vm.dialog, "open", $$v)},expression:"dialog.open"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }